import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {locale as english} from './../i18n/en-US';
import {locale as portugues} from './../i18n/pt-BR';
import {SelectTimeComponent} from '../../../../../core/components/select-time/select-time.component';
import {Event} from '../../../../../core/model/event';
import {Championship} from '../../../../../core/model/championship';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuillComponent, QuillConfigInterface, QuillModulesInterface} from 'ngx-quill-wrapper';
import {Observable, Subscription} from 'rxjs';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {DragulaService} from 'ng2-dragula';
import {EventsService} from '../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../core/services/event-configurations/event-configurations.service';
import {config} from '../../../../../core/config/config';
// import { } from '@types/googlemaps'
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import {Utils} from '../../../../../core/utils';
import {dateValidator} from '../../../../../core/utils/custom-validators';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {map, startWith} from 'rxjs/operators';
import {Errors} from '../../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {CropDialogComponent} from '../../../../../core/components/crop-dialog/crop-dialog.component';

@Component({
  selector: 'app-event-informations',
  templateUrl: './event-informations.component.html',
  styleUrls: ['./event-informations.component.scss']
})
export class EventInformationsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pickerTimeStart', {read: SelectTimeComponent}) pickerTimeStart: SelectTimeComponent;
  @ViewChild('pickerTimeEnd', {read: SelectTimeComponent}) pickerTimeEnd: SelectTimeComponent;
  @ViewChild('nameEventInput') nameEventInput: ElementRef;
  @ViewChild('quill') quill: QuillComponent;
  // Translate 
  translate: any;
  // Event
  event: Event;
  eventName = '';
  // Championships
  championships: Championship[] = [];
  // Event Form
  eventForm: FormGroup;
  eventMatchers: any;
  banks = [];
  public cities = [];
  markers = [];
  placeId = '';
  timeStart = '10:00';
  timeEnd = '10:00';
  timezoneSelected: string;
  timezoneRequired = false;
  brandUrl = '';
  public brandFileToUpload: any = null;
  headerUrl = '';
  public headerFileToUpload: any = null;
  regulationFileName = '';
  removeRegulationFile = false;
  public regulationFileToUpload: File = null;
  termsFileName = '';
  removeTermsFile = false;
  public termsFileToUpload: File = null;
  userMe: any = null;
  public configEditor: QuillConfigInterface = {
    readOnly: false,
  };
  public modules: QuillModulesInterface = {};
  configCrop = {
    checkCrossOrigin: false
  };
  lat = -23.549063;
  lng = -46.633460;
  isShowMarker = false;
  timezoneData: any[] = [];
  filterdTimezoneData: Observable<any[]>;
  timeOutSearchBank;
  isLoading = false;
  isPublishLoading = false;
  private subscriptions: Subscription = new Subscription();
  private toolbar: any = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{'align': []}],
    ['link']
  ];

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('SETTINGS').subscribe((res: any) => {
      this.translate = res;
    }));

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/event/informations`
      }]);
    }

    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
    this.eventName = this.event.name;
    this.timeStart = (this.event.datetime_start) ? moment(this.event.datetime_start).format('HH:mm') : '10:00';
    this.timeStart = (this.event.datetime_end) ? moment(this.event.datetime_end).format('HH:mm') : '10:00';
    // this.championships = this.event.championships

    this.timezoneSelected = (this.event.timezone && this.event.timezone.length > 0) ? this.event.timezone : null;

    if (this.event.championships && this.event.championships.length > 0) {
      this.event.championships.forEach(championship => {
        this.championships.push(new Championship().deserialize(championship));
      });
    }

    // this.modules = { 'modules/focus': Focus };
    this.configEditor.modules = {toolbar: this.toolbar};
    this.configEditor.placeholder = this.translate.FIELDS.DESCRIPTION;

    this.userMe = this.authGuard.getMe().user;

  }

  ngAfterViewInit() {
    // this.nameEventInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    let tz: any;
    if (this.event.timezone) {
      const momentNames = momentTimezone.tz.names();
      const now = momentTimezone();

      momentNames.map((name) => {
        if (name === this.event.timezone) {
          tz = {
            text: `(GMT${now.tz(name).format('Z')}) ${name}`,
            value: name,
          };
        }
      });

    }

    // Slug
    let url = this.event.slug;
    if ((this.event.slug === null || this.event.slug.length === 0) && this.event.name) {
      const date = this.event.datetime_start ? moment(this.event.datetime_start, this.translate.DATE_FORMAT).format('YYYY') : moment().format('YYYY');
      url = `${Utils.removeAccents(this.event.name).toLowerCase().replace(/[^A-Z0-9]/ig, '')}${date}`;
    }

    // Event Form
    this.eventForm = this.formBuilder.group({
      name: new FormControl(this.event.name, [Validators.required]),
      address: [this.event.address, Validators.required],
      location: [this.event.location, Validators.required],
      timezone: [tz, Validators.required],
      dateStart: [(this.event.datetime_start) ? moment(this.event.datetime_start).format(this.translate.DATE_FORMAT) : '', [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      dateEnd: [(this.event.datetime_end) ? moment(this.event.datetime_end).format(this.translate.DATE_FORMAT) : '', [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      urlEvent: [url, [Validators.required, Validators.minLength(4)]],
      regulation: [''],
      terms: [''],
    });

    this.eventMatchers = {
      name: new FormErrorStateMatcher(),
      address: new FormErrorStateMatcher(),
      location: new FormErrorStateMatcher(),
      timezone: new FormErrorStateMatcher(),
      dateStart: new FormErrorStateMatcher(),
      dateEnd: new FormErrorStateMatcher(),
      urlEvent: new FormErrorStateMatcher(),
      regulation: new FormErrorStateMatcher(),
      terms: new FormErrorStateMatcher(),
    };

    this.getEventDetail();

    this.filterdTimezoneData = this.eventForm.get('timezone').valueChanges
      .pipe(
        startWith(''),
        map(timezone => timezone ? this._filterStates(timezone) : this.timezoneData.slice())
      );


    // Timezones
    this.getTimezones();

    // Inputs value changes
    this.inputValueChanged();
  }

  inputValueChanged(): any {
    const name = this.eventForm.get('name').valueChanges.subscribe(value => {
      this.eventName = (value.length > 0) ? value.toUpperCase() : '';

      if (this.event.slug === null || this.event.slug.length === 0) {
        const date = this.eventForm.value.dateStart ? moment(this.eventForm.value.dateStart, this.translate.DATE_FORMAT).format('YYYY') : moment().format('YYYY');
        const url = `${Utils.removeAccents(value).toLowerCase().replace(/[^A-Z0-9]/ig, '')}${date}`;
        this.eventForm.controls['urlEvent'].setValue(url);
      }
    });

    this.subscriptions.add(name);

    const address = this.eventForm.get('address').valueChanges.subscribe(value => {
      this.searchAddress(value);
    });
    this.subscriptions.add(address);


  }

  getEventDetail() {
    // Get Event details
    this.subscriptions.add(this.eventService.getEventInformation(this.event.id)
      .subscribe(
        result => {
          this.event = new Event().deserialize(result);
          this.quill.value = this.event.description ? this.event.description : '';
          this.eventName = this.event.name;
          if (this.event.datetime_start) {
            this.timeStart = moment(this.event.datetime_start).format('HH:mm');
            this.pickerTimeStart.updateValue(this.timeStart);
          }

          if (this.event.datetime_end) {
            this.timeEnd = moment(this.event.datetime_end).format('HH:mm');
            this.pickerTimeEnd.updateValue(this.timeEnd);
          }
          // this.timeStart = (this.event.datetime_start) ? moment(this.event.datetime_start.date).format('HH:mm') : '10:00'
          // this.timeEnd = (this.event.datetime_end) ? moment(this.event.datetime_end.date).format('HH:mm') : '10:00'
          this.championships = this.event.championships;

          if (this.event.email_logo !== null && this.event.email_logo.length > 0) {
            this.brandUrl = this.event.email_logo;
          }

          if (this.event.header_image !== null && this.event.header_image.length > 0) {
            this.headerUrl = this.event.header_image;
          }

          if (this.event.place_id && this.event.place_id.length > 0) {
            this.placeId = this.event.place_id;
            this.subscriptions.add(this.googlePlacesService.placeIdDetail(this.event.place_id)
              .subscribe(
                (result) => {
                  this.lat = result.geometry.location.lat();
                  this.lng = result.geometry.location.lng();
                  this.isShowMarker = true;

                }, err => {

                }
              ));
          }

          let tz: any;
          if (result.timezone) {
            const momentNames = momentTimezone.tz.names();
            const now = momentTimezone();

            momentNames.map((name) => {
              if (name === result.timezone) {
                tz = {
                  text: `(GMT${now.tz(name).format('Z')}) ${name}`,
                  value: name,
                };
              }
            });
          }

          this.eventForm.controls['name'].setValue((result.name) ? result.name : this.eventForm.value.name);
          this.eventForm.controls['address'].setValue((result.address) ? result.address : this.eventForm.value.address);
          this.eventForm.controls['location'].setValue((result.location) ? result.location : this.eventForm.value.location);
          this.eventForm.controls['timezone'].setValue((tz) ? tz : this.eventForm.value.timezone);
          this.eventForm.controls['urlEvent'].setValue((result.slug) ? result.slug : this.eventForm.value.urlEvent);

        }, err => {
        }
      ));
  }

  // Update event detail
  updateEventDetail() {
    if (!this.eventForm.valid) {
      Utils.formValid(this.eventForm);
      return;
    }

    const event = {
      name: this.eventForm.value.name.toUpperCase(),
      address: this.eventForm.value.address,
      place_id: this.placeId,
      latitude: this.placeId && this.placeId !== '' ? this.lat : null,
      longitude: this.placeId && this.placeId !== '' ? this.lng : null,
      location: this.eventForm.value.location,
      timezone: this.eventForm.value.timezone.value,
      slug: this.eventForm.value.urlEvent,
      description: Utils.removeParagraphInText(this.event.description ? this.event.description : ''),
    };

    if (this.eventForm.value.dateStart) {
      const dateTimeStart = moment(`${this.eventForm.value.dateStart} ${this.timeStart}:00`, this.translate.DATETIME_FORMAT);
      if (dateTimeStart.isValid()) {
        event['datetime_start'] = dateTimeStart.format('YYYY-MM-DD HH:mm:ss');
        this.eventForm.controls['dateStart'].setErrors(null);
      } else {
        this.eventForm.controls['dateStart'].setErrors({
          invalid: true
        });
      }
    }

    if (this.eventForm.value.dateEnd) {
      const dateTimeEnd = moment(`${this.eventForm.value.dateEnd} ${this.timeEnd}:00`, this.translate.DATETIME_FORMAT);
      if (dateTimeEnd.isValid()) {
        event['datetime_end'] = dateTimeEnd.format('YYYY-MM-DD HH:mm:ss');
        this.eventForm.controls['dateEnd'].setErrors(null);
      } else {
        this.eventForm.controls['dateEnd'].setErrors({
          invalid: true
        });
      }
    }


    const media = {
      brand: this.brandFileToUpload,
      header: this.headerFileToUpload,
      term: this.termsFileToUpload,
      regulation: this.regulationFileToUpload,
      remove_regulation: this.removeRegulationFile,
      remove_term: this.removeTermsFile,
    };

    this.isLoading = true;
    this.subscriptions.add(this.eventService.updateEvent(this.event.id, event, media)
      .subscribe(
        result => {
          this.isLoading = false;
          this.eventGuard.updateEventInformation(result);
          // this.eventForm.controls['dateStart'].setErrors(null)
          // this.eventForm.controls['dateEnd'].setErrors(null)
          this.toastr.show(
            this.translate.TOASTR.UPDATE.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.UPDATE.SUCCESS.TITLE,
            null,
            'success',
          );
        }, err => {
          this.isLoading = false;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.UPDATE.ERROR.TITLE,
            null,
            'error',
          );
        }
      ));
  }

  publishOrUnpublishEvent() {
    if (!this.eventForm.valid) {
      return;
    }
    if (this.event.published) {
      this.unpublishEvent();
    } else {
      this.publishEvent();
    }
  }

  publishEvent() {
    if (!this.eventForm.valid) {
      Utils.formValid(this.eventForm);
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.TITLE', {action: this.translate.EVENT_PUBLISH_DIALOG.PUBLISH}).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.DESCRIPTION', {action: this.translate.EVENT_PUBLISH_DIALOG.PUBLISH}).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.EVENT_PUBLISH_DIALOG.PUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isPublishLoading = true;

        const event = {
          // _method: 'PUT',
          name: this.eventForm.value.name.toUpperCase(),
          address: this.eventForm.value.address,
          place_id: this.placeId,
          location: this.eventForm.value.location,
          timezone: this.eventForm.value.timezone.value,
          slug: this.eventForm.value.urlEvent,
          description: this.event.description,
          published: 1,
        };

        const endDateOld = this.eventForm.value.dateEnd;
        const dateStartOld = this.eventForm.value.dateStart;

        if (this.eventForm.value.dateStart) {
          const dateTimeStart = moment(`${this.eventForm.value.dateStart} ${this.timeStart}:00`, this.translate.DATETIME_FORMAT);
          if (dateTimeStart.isValid()) {
            event['datetime_start'] = dateTimeStart.format('YYYY-MM-DD HH:mm:ss');
            this.eventForm.controls['dateStart'].setErrors({
              invalid: false
            });
          } else {
            this.eventForm.controls['dateStart'].setErrors({
              invalid: true
            });
          }
        }
        if (this.eventForm.value.dateEnd) {
          const dateTimeEnd = moment(`${this.eventForm.value.dateEnd} ${this.timeEnd}:00`, this.translate.DATETIME_FORMAT);
          if (dateTimeEnd.isValid()) {
            event['datetime_end'] = dateTimeEnd.format('YYYY-MM-DD HH:mm:ss');
            this.eventForm.controls['dateEnd'].setErrors({
              invalid: false
            });
          } else {
            this.eventForm.controls['dateEnd'].setErrors({
              invalid: true
            });
          }
        }


        const media = {
          brand: this.brandFileToUpload,
          header: this.headerFileToUpload,
          term: this.termsFileToUpload,
          regulation: this.regulationFileToUpload
        };


        this.subscriptions.add(this.eventService.updateEvent(this.event.id, event, media)
          .subscribe(
            result => {
              this.isPublishLoading = false;
              this.event.published = true;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.TOASTR.PUBLISH_DESCRIPTION,
                this.translate.TOASTR.PUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.UPDATE.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));

        this.eventForm.controls['dateEnd'].setValue(endDateOld);
        this.eventForm.controls['dateStart'].setValue(dateStartOld);
      }
    }));
  }

  unpublishEvent() {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.TITLE', {action: this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH}).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.DESCRIPTION', {action: this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH}).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isPublishLoading = true;

        const parameters = {
          published: 0
        };

        this.subscriptions.add(this.eventService.updateEvent(this.event.id, parameters)
          .subscribe(
            result => {
              this.isPublishLoading = false;
              this.event.published = false;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.TOASTR.UNPUBLISH_DESCRIPTION,
                this.translate.TOASTR.UNPUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));

      }
    }));
  }

  displayFnTimezone(timezone: any): string {
    return timezone ? timezone.text : timezone;
  }

  displayFnBank(bank: any): string {
    return bank ? `${bank.code} - ${bank.title}` : bank;
  }

  // Description Value change
  public onValueChange(value: string): void {
    this.event.description = value;
  }

  startTimeChange(time) {
    this.timeStart = time;
  }

  endTimeChange(time) {
    this.timeEnd = time;
  }

  removeDoc(type: string, event: any) {
    switch (type) {
      case 'terms':
        this.termsFileName = '';
        this.removeTermsFile = true;
        event.doc_term = null;
        break;
      case 'regulation':
        this.regulationFileName = '';
        this.removeRegulationFile = true;
        event.doc_rule = null;
        break;
    }

  }

  readUrl(event: any, type: string): void {
    const inputElement = event.target as HTMLInputElement;

    if (!inputElement.files || inputElement.files.length === 0) {
      console.warn('Nenhum arquivo selecionado.');
      return;
    }

    const file = inputElement.files[0];
    
    if (!this.isValidFileName(file.name)) {
      console.error('Nome de arquivo inválido:', file.name);
      return;
    }

    const reader = new FileReader();

    reader.onload = (readerEvent: any) => {
      if (!readerEvent.target || !readerEvent.target.result) {
        return;
      }

      switch (type) {
        case 'brand':
          this.openCropDialog(readerEvent.target.result as string, 500, 500)
            .then(result => {
              if (result) {
                this.brandUrl = result.image;
                this.brandFileToUpload = result.blob;
              }
            });
          break;

        case 'header':
          this.openCropDialog(readerEvent.target.result as string, 1000, 320)
            .then(result => {
              if (result) {
                this.headerUrl = result.image;
                this.headerFileToUpload = result.blob;
              }
            });
          break;

        case 'regulation':
          this.regulationFileName = file.name;
          this.regulationFileToUpload = file;
          break;

        case 'terms':
          this.termsFileName = file.name;
          this.termsFileToUpload = file;
          break;

        default:
          console.warn('Tipo de arquivo não reconhecido:', type);
      }
    };

    reader.readAsDataURL(file);
  }

  // Search Bank
  searchBank(bank: string) {
    clearTimeout(this.timeOutSearchBank);
    this.timeOutSearchBank = setTimeout(() => {
      if (bank.length > 0) {
        this.subscriptions.add(this.banksService.search(bank)
          .subscribe(
            result => {
              this.banks = result;
            }, err => {
              this.banks = [];
            }
          ));
      } else {
        this.banks = [];
      }
    }, 300);

  }

  searchAddress(city: string) {
    if (city && city.length > 2) {

      this.subscriptions.add(this.googlePlacesService.search(city)
        .subscribe(
          result => {
            this.cities = result;
          }, err => {
            this.cities = [];

          }
        ));
    }
  }

  addressSelected(event) {
    this.placeId = event.place_id;
    this.subscriptions.add(this.googlePlacesService.placeIdDetail(event.place_id)
      .subscribe(
        result => {
          this.lat = result.geometry.location.lat();
          this.lng = result.geometry.location.lng();
          this.isShowMarker = true;

        }, err => {

        }
      ));
  }

  // Get Timezone
  getTimezones() {
    const momentNames = momentTimezone.tz.names();
    const now = momentTimezone();

    this.timezoneData = momentNames.map((name) => {
      return {
        text: `(GMT${now.tz(name).format('Z')}) ${name}`,
        value: name,
      };
    });

  }

  private _filterStates(value: any): any[] {
    const filterValue = (typeof value === 'string') ? Utils.removeAccents(value.toLowerCase()) : Utils.removeAccents(value.text.toLowerCase());
    return this.timezoneData.filter(timezone => timezone.text.toLowerCase().indexOf(filterValue) !== -1);
  }

  /**
   * Abre o modal de recorte para imagens.
   */
  private openCropDialog(image: string, width: number, height: number): Promise<any> {
    const modalRef = this.dialog.open(CropDialogComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.image = image;
    modalRef.componentInstance.configCrop = {
      checkCrossOrigin: false,
      viewMode: 0,
      dragMode: 'move',
      zoomOnWheel: true,
      zoomable: true,
      aspectRatio: width / height,
      cropBoxResizable: true,
      cropBoxMovable: true
    };
    modalRef.componentInstance.width = width;
    modalRef.componentInstance.height = height;
    modalRef.componentInstance.minWidth = width;
    modalRef.componentInstance.minHeight = height;

    return modalRef.afterClosed().toPromise();
  }

  /**
   * Verifica se o nome do arquivo contém caracteres inválidos.
   */
  private isValidFileName(fileName: string): boolean {
    const invalidChars = /[<>:"\/\\|?*]/;
    return !invalidChars.test(fileName);
  }

}
