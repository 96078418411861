<div class="page-layout blank pb-64">
    <div id="event" fxLayoutAlign="center">
        <div class="background-img">
            <img src="{{ event.header_image ? event.header_image : headerPlaceholder }}" />
        </div>

        <div id="event-wrapper" class="mt-60" class.xs="mt-0">
            <!-- Manager Event -->
            <div class="card auto-width mb-56" [ngClass.gt-sm]="'mb-0'">
                <div fxLayout="row" fxLayoutAlign="start center" class="p-32">
                    <img src="{{ event.email_logo ? event.email_logo : (null | placeholderImage : 'user') }}" width="110" class="mr-24" />

                    <div fxLayout="column" fxLayoutAlign="start start">
                        <h2 class="m-0">
                            <a [routerLink]="'/event'">{{ event.name | uppercase }}</a>
                        </h2>
                        <div
                            fxLayout="row"
                            fxLayout.xs="column"
                            fxLayoutAlign="start center"
                            fxLayoutAlign.xs="center start"
                            class="event-information"
                        >
                            <span class="mr-8" fxLayoutAlign="start center">
                                <mat-icon class="mr-4">date_range</mat-icon
                                >{{ event.datetime_start | localizedDate : ("REGISTRATION.DATE_FORMAT_COMPLETE" | translate) }}
                            </span>
                            <span fxLayoutAlign="start center" *ngIf="event?.address">
                                <mat-icon class="mr-4">location_on_outlined</mat-icon>{{ event?.address }}
                            </span>
                        </div>
                    </div>
                </div>

                <table-x [items]="[lot]" [config]="tableConfig">
                    <!-- (sortEvent)="sortAction($event)" -->
                    <column
                        property="competitionName"
                        header="{{ 'REGISTRATION.TABLE_HEADER.LOT' | translate }}"
                        [sortable]="false"
                        align="left"
                    >
                        <ng-template #dataTableCell let-item="item">
                            <a class="pt-16 pb-16">
                                {{ item.competition ? item.competition.name : item.name }}
                            </a>
                        </ng-template>
                    </column>

                    <column property="price" header="{{ 'REGISTRATION.TABLE_HEADER.PRICE' | translate }}" [sortable]="false" align="center">
                        <ng-template #dataTableCell let-item="item">
                            {{
                                formatCurrency(
                                    item.price | currency : ("REGISTRATION.CURRENCY_CODE" | translate) : "symbol-narrow" : "1.2-2",
                                    "REGISTRATION.CURRENCY_CODE" | translate
                                )
                            }}
                        </ng-template>
                    </column>

                    <column
                        property="tax"
                        header="{{ 'REGISTRATION.TABLE_HEADER.TAX' | translate }}"
                        [sortable]="false"
                        align="center"
                        *ngIf="lot.taxPrice > 0"
                    >
                        <ng-template #dataTableCell let-item="item">
                            {{
                                formatCurrency(
                                    item.taxPrice | currency : ("REGISTRATION.CURRENCY_CODE" | translate) : "symbol-narrow" : "1.2-2",
                                    "REGISTRATION.CURRENCY_CODE" | translate
                                )
                            }}
                        </ng-template>
                    </column>

                    <column
                        property="discount"
                        header="{{ 'REGISTRATION.TABLE_HEADER.DISCOUNT' | translate }}"
                        [sortable]="false"
                        align="center"
                    >
                        <ng-template #dataTableCell let-item="item">
                            {{
                                coupon
                                    ? coupon.type === 0
                                        ? coupon.value + "%"
                                        : formatCurrency(
                                              coupon.value
                                                  | currency : ("REGISTRATION.CURRENCY_CODE" | translate) : "symbol-narrow" : "1.2-2",
                                              "REGISTRATION.CURRENCY_CODE" | translate
                                          )
                                    : "0"
                            }}
                        </ng-template>
                    </column>

                    <column
                        property="total_price"
                        header="{{ 'REGISTRATION.TABLE_HEADER.TOTAL_PRICE' | translate }}"
                        [sortable]="false"
                        align="center"
                    >
                        <ng-template #dataTableCell let-item="item">
                            <strong>{{
                                formatCurrency(
                                    item.finalPrice | currency : ("REGISTRATION.CURRENCY_CODE" | translate) : "symbol-narrow" : "1.2-2",
                                    "REGISTRATION.CURRENCY_CODE" | translate
                                )
                            }}</strong>
                        </ng-template>
                    </column>
                </table-x>

                <span fxLayout="row" fxLayoutAlign="center" class="mt-32">
                    <h3 class="text-red" *ngIf="hideSubscription">{{ "REGISTRATION.ERRORS.SUBSCRIBE_NOT_PERMITTED" | translate }}</h3>
                </span>

                <form class="" name="registrationForm" *ngIf="!hideSubscription" [formGroup]="registrationForm" novalidate>
                    <div class="p-32">
                        <!-- TEAM INFORMATION -->
                        <div class="mb-32" *ngIf="subDivisions.length > 0">
                            <h5>{{ "REGISTRATION.SUB_DIVISION" | translate : { sub_division_name: subDivisionName | lowercase } }}</h5>
                            <div class="box-line p-24">
                                <div fxLayout="column">
                                    <div class="sub-division-list">
                                        <mat-radio-group formControlName="subDivision">
                                            <mat-radio-button
                                                [disabled]="!item.canSubscribe"
                                                fxFlex="50"
                                                value="{{ item.id }}"
                                                class="mb-8"
                                                *ngFor="let item of subDivisions"
                                            >
                                                {{ item.name }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <mat-error *ngIf="registrationForm.controls.subDivision.hasError('required')">
                                        {{
                                            "REGISTRATION.VALIDATIONS.SUB_DIVISION_REQUIRED"
                                                | translate : { sub_division_name: subDivisionName | titlecase }
                                        }}
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="mb-24" *ngIf="isTeam">
                            <h5>{{ "REGISTRATION.TEAM_INFORMATION" | translate }}</h5>
                            <div class="box-line p-24">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start stretch">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8 pl-8" [hideRequiredMarker]="!isTeam">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.TEAM_NAME' | translate }}"
                                            formControlName="teamName"
                                            [errorStateMatcher]="matchers.teamName"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="isTeam && registrationForm.controls.teamName.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.TEAM_NAME_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" fxFlex.xs="auto" class="pr-8 pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.TEAM_BOX' | translate }}"
                                            formControlName="teamBox"
                                            [matAutocomplete]="autoBox"
                                            (ngModelChange)="searchBox($event)"
                                            uppercase
                                        />
                                        <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                                            <mat-option *ngFor="let box of boxes" [value]="box">
                                                {{ box.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="registrationForm.controls.teamBox.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.TEAM_BOX_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!-- END TEAM INFORMATION -->

                        <!-- PERSONAL INFORMATION -->
                        <h5>{{ "REGISTRATION.PERSONAL_INFORMATION" | translate }}</h5>
                        <div class="box-line p-24 pl-16 pr-16">
                            <div fxLayout="row" fxLayoutAlign="start center" class="mb-32 pl-8 pr-8" *ngIf="isTeam">
                                <h3 class="m-0">
                                    {{ registrationForm?.value?.firstname | uppercase }} {{ registrationForm?.value?.lastname | uppercase }}
                                </h3>
                                <label-x type="normal" class="ml-16">{{ "REGISTRATION.CAPTAIN" | translate | uppercase }}</label-x>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" class="small-font mb-24 pl-8 pr-8" *ngIf="user !== null">
                                <img
                                    src="{{ user.avatar !== null ? user.avatar : (null | placeholderImage : 'user') }}"
                                    width="80"
                                    class="avatar corner-radius"
                                />
                                <div fxLayout="column" fxLayoutAlign="start start" class="ml-4">
                                    <strong>{{ user.fullname }}</strong>
                                    <span class="user-exist">{{ user.email }}</span>
                                </div>
                            </div>

                            <div
                                fxLayout="row"
                                fxLayout.xs="column"
                                fxLayoutAlign="start start"
                                fxLayoutAlign.xs="start stretch"
                                *ngIf="user === null"
                            >
                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
                                    <input
                                        matInput
                                        placeholder="E-mail"
                                        formControlName="email"
                                        [errorStateMatcher]="matchers.email"
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.email.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.EMAIL_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="
                                            registrationForm.controls.email.hasError('email') &&
                                            !registrationForm.controls.email.hasError('required')
                                        "
                                    >
                                        {{ "REGISTRATION.VALIDATIONS.EMAIL_INVALID" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div
                                fxLayout="row"
                                fxLayout.xs="column"
                                fxLayoutAlign="start start"
                                fxLayoutAlign.xs="start stretch"
                                *ngIf="user === null"
                            >
                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
                                    <input
                                        matInput
                                        minlength="6"
                                        placeholder="{{ 'REGISTRATION.FORM.PASSWORD' | translate }}"
                                        formControlName="password"
                                        [errorStateMatcher]="matchers.password"
                                        [type]="hidePassword ? 'password' : 'text'"
                                        required
                                    />
                                    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
                                        hidePassword ? "visibility" : "visibility_off"
                                    }}</mat-icon>
                                    <mat-error *ngIf="registrationForm.controls.password.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.PASSWORD_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="registrationForm.controls.password.hasError('minlength')">
                                        {{ "REGISTRATION.VALIDATIONS.PASSWORD_MINLENGHT" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
                                    <input
                                        matInput
                                        minlength="6"
                                        placeholder="{{ 'REGISTRATION.FORM.CONFIRM_PASSWORD' | translate }}"
                                        formControlName="confirmPassword"
                                        [errorStateMatcher]="matchers.confirmPassword"
                                        [type]="hideConfirmPassword ? 'password' : 'text'"
                                        required
                                    />
                                    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{
                                        hidePassword ? "visibility" : "visibility_off"
                                    }}</mat-icon>
                                    <mat-error *ngIf="registrationForm.controls.confirmPassword.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.CONFIRM_PASSWORD_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="registrationForm.controls.confirmPassword.hasError('passwordsNotMatch')">
                                        {{ "REGISTRATION.VALIDATIONS.CONFIRM_PASSWORD_INVALID" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div
                                fxLayout="row"
                                fxLayout.xs="column"
                                fxLayoutAlign="start start"
                                fxLayoutAlign.xs="start stretch"
                                *ngIf="user === null"
                            >
                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.FIRSTNAME' | translate }}"
                                        formControlName="firstname"
                                        [errorStateMatcher]="matchers.firstname"
                                        uppercase
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.firstname.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.FIRSTNAME_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.LASTNAME' | translate }}"
                                        formControlName="lastname"
                                        [errorStateMatcher]="matchers.lastname"
                                        uppercase
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.lastname.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.LASTNAME_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row wrap">
                                <mat-form-field fxFlex="0 1 50%" fxFlex.xs="0 1 100%" class="pl-8 pr-8">
                                    <mat-select
                                        placeholder="{{ 'REGISTRATION.FORM.GENDER' | translate }}"
                                        formControlName="gender"
                                        required
                                    >
                                        <mat-option *ngFor="let gender of genders" [value]="gender">
                                            {{ gender.text }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="registrationForm.controls.gender.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.GENDER_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="!defaultInputConfig.cpf.hidden"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.CPF' | translate }}"
                                        formControlName="cpf"
                                        [errorStateMatcher]="matchers.cpf"
                                        mask="000.000.000-00"
                                        [required]="defaultInputConfig.cpf.required"
                                    />
                                    <mat-error *ngIf="registrationForm.controls.cpf.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.CPF_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="!defaultInputConfig.phone.hidden"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.PHONE' | translate }}"
                                        formControlName="phone"
                                        [errorStateMatcher]="matchers.phone"
                                        mask="(00) 00000.0000"
                                        [required]="defaultInputConfig.phone.required"
                                    />
                                    <mat-error *ngIf="registrationForm.controls.phone.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.PHONE_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="!defaultInputConfig.birthdate.hidden"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.BIRTHDATE' | translate }}"
                                        formControlName="birthdate"
                                        [errorStateMatcher]="matchers.birthdate"
                                        mask="00/00/0000"
                                        [required]="defaultInputConfig.birthdate.required"
                                    />
                                    <mat-icon matSuffix>date_range</mat-icon>
                                    <mat-error *ngIf="registrationForm.controls.birthdate.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.BIRTHDATE_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="
                                            !registrationForm.controls.birthdate.hasError('required') &&
                                            registrationForm.controls.birthdate.hasError('invalid')
                                        "
                                    >
                                        {{ "REGISTRATION.VALIDATIONS.DATE_INVALID" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="!defaultInputConfig.box.hidden"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.BOX' | translate }}"
                                        formControlName="box"
                                        [matAutocomplete]="autoBoxUser"
                                        [errorStateMatcher]="matchers.box"
                                        (ngModelChange)="searchBox($event)"
                                        uppercase
                                        [required]="defaultInputConfig.box.required"
                                    />
                                    <mat-autocomplete #autoBoxUser="matAutocomplete" [displayWith]="displayFnBox">
                                        <mat-option *ngFor="let box of boxes" [value]="box">
                                            {{ box.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="registrationForm.controls.box.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.BOX_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="!defaultInputConfig.tshirt.hidden"
                                >
                                    <mat-select
                                        placeholder="{{ 'REGISTRATION.FORM.TSHIRT' | translate }}"
                                        formControlName="tshirt"
                                        [required]="defaultInputConfig.tshirt.required"
                                    >
                                        <mat-option *ngFor="let size of tshirtSize" [value]="size">
                                            {{ size }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="registrationForm.controls.tshirt.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.TSHIRT_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="0 1 50%"
                                    fxFlex.xs="0 1 100%"
                                    class="pl-8 pr-8"
                                    *ngIf="user === null && !defaultInputConfig.address.hidden"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ 'REGISTRATION.FORM.CITY' | translate }}"
                                        formControlName="city"
                                        [errorStateMatcher]="matchers.city"
                                        (ngModelChange)="searchCity($event)"
                                        [matAutocomplete]="autoCity"
                                        autocomplete="off"
                                        uppercase
                                        [required]="defaultInputConfig.address.required"
                                    />
                                    <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                                        <mat-option
                                            *ngFor="let city of cities"
                                            [value]="city.description | uppercase"
                                            (onSelectionChange)="citySelected(city)"
                                        >
                                            {{ city.description }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="registrationForm.controls.city.hasError('required')">
                                        {{ "REGISTRATION.VALIDATIONS.CITY_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <ng-container *ngFor="let customField of customFields; let index = index">
                                    <custom-field
                                        [customField]="customField"
                                        [form]="registrationForm"
                                        [fxFlex]="customFieldInOneColumn(customField) ? '0 1 100%' : '0 1 50%'"
                                        fxFlex.xs="0 1 100%"
                                        class="pl-8 pr-8"
                                    >
                                    </custom-field>
                                </ng-container>
                            </div>
                        </div>
                        <!-- ENDPERSONAL INFORMATION -->

                        <!-- TEAM MEMBERS -->
                        <div class="mb-24" *ngIf="isTeam">
                            <h5>{{ "REGISTRATION.TEAM_MEMBERS" | translate : { count: members.length, total: totalMembers } }}</h5>

                            <div fxLayout="column" fxLayoutAlign="start stretch">
                                <div
                                    [ngClass]="{ 'box-line': !isEdit, 'p-16': !isEdit }"
                                    class="mb-16"
                                    *ngFor="let member of members; let i = index"
                                >
                                    <btn-transparent
                                        icon="common/ic-arrow-up-collapsible.svg"
                                        buttonWidth="35px"
                                        iconAlign="left"
                                        [ngClass]="{ 'rotate-180': !member.isOpen }"
                                        class="btn-collapsible"
                                        (click)="actionCollapsible(); member.isOpen = !member.isOpen"
                                    >
                                    </btn-transparent>
                                    <div fxLayout="row" fxLayoutAlign="start center" class="mt-4">
                                        <h3 class="m-0">{{ member.fullname | uppercase }}</h3>
                                    </div>

                                    <div [fxShow]="member.isOpen">
                                        <form [formGroup]="member.competitorForm" name="competitorForm" autocomplete="off" novalidate>
                                            <div fxLayout="column" fxLayoutAlign="stretch stretch">
                                                <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-16">
                                                    <mat-form-field fxFlex="0 1 50%" fxFlex.xs="0 1 100%" class="pl-8 pr-8">
                                                        <input
                                                            matInput
                                                            placeholder="E-mail"
                                                            formControlName="email"
                                                            [errorStateMatcher]="member.matchers.email"
                                                            (blur)="searchUser($event, member)"
                                                            required
                                                        />
                                                        <mat-error *ngIf="member.competitorForm.controls.email.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.EMAIL_REQUIRED" | translate }}
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="
                                                                member.competitorForm.controls.email.hasError('email') &&
                                                                !member.competitorForm.controls.email.hasError('required')
                                                            "
                                                        >
                                                            {{ "REGISTRATION.VALIDATIONS.EMAIL_INVALID" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div
                                                    fxLayout="column"
                                                    fxLayoutAlign="start start"
                                                    class="user"
                                                    *ngIf="
                                                        member.user.id !== null && member.user.email !== null && member.user.email !== ''
                                                    "
                                                >
                                                    <div fxLayout="row wrap" fxLayoutAlign="start center" class="small-font">
                                                        <img
                                                            src="{{
                                                                member.user.avatar !== null
                                                                    ? member.user.avatar
                                                                    : (null | placeholderImage : 'user')
                                                            }}"
                                                            width="80"
                                                            class="avatar corner-radius"
                                                        />
                                                        {{ member?.user?.firstname }} {{ member?.user?.lastname }}
                                                    </div>
                                                    <span class="user-exist mt-16">Usuário encontrado em nossa base de dados.</span>
                                                </div>

                                                <div fxLayout="row wrap" *ngIf="member.user.email === null || member.user.email === ''">
                                                    <mat-form-field fxFlex="0 1 50%" fxFlex.xs="0 1 100%" class="pl-8 pr-8">
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.FIRSTNAME' | translate }}"
                                                            formControlName="firstname"
                                                            [errorStateMatcher]="member.matchers.firstname"
                                                            uppercase
                                                            required
                                                        />
                                                        <mat-error *ngIf="member.competitorForm.controls.firstname.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.FIRSTNAME_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field fxFlex="0 1 50%" fxFlex.xs="0 1 100%" class="pl-8 pr-8">
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.LASTNAME' | translate }}"
                                                            formControlName="lastname"
                                                            [errorStateMatcher]="member.matchers.lastname"
                                                            uppercase
                                                            required
                                                        />
                                                        <mat-error *ngIf="member.competitorForm.controls.lastname.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.LASTNAME_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div fxLayout="row wrap">
                                                    <mat-form-field fxFlex="0 1 50%" fxFlex.xs="0 1 100%" class="pl-8 pr-8">
                                                        <mat-select
                                                            placeholder="{{ 'REGISTRATION.FORM.GENDER' | translate }}"
                                                            formControlName="gender"
                                                            required
                                                        >
                                                            <mat-option *ngFor="let gender of genders" [value]="gender">
                                                                {{ gender.text }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="member.competitorForm.controls.gender.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.GENDER_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.cpf.hidden"
                                                    >
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.CPF' | translate }}"
                                                            formControlName="cpf"
                                                            [errorStateMatcher]="member.matchers.cpf"
                                                            mask="000.000.000-00"
                                                            [required]="defaultInputConfig.cpf.required"
                                                        />
                                                        <mat-error
                                                            *ngIf="
                                                                defaultInputConfig.cpf.required &&
                                                                member.competitorForm.controls.cpf.hasError('required')
                                                            "
                                                        >
                                                            {{ "REGISTRATION.VALIDATIONS.CPF_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.phone.hidden"
                                                    >
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.PHONE' | translate }}"
                                                            formControlName="phone"
                                                            [errorStateMatcher]="member.matchers.phone"
                                                            mask="(00) 00000.0000"
                                                            [required]="defaultInputConfig.phone.required"
                                                        />
                                                        <mat-error
                                                            *ngIf="
                                                                defaultInputConfig.phone.required &&
                                                                member.competitorForm.controls.phone.hasError('required')
                                                            "
                                                        >
                                                            {{ "REGISTRATION.VALIDATIONS.PHONE_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.birthdate.hidden"
                                                    >
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.BIRTHDATE' | translate }}"
                                                            formControlName="birthdate"
                                                            [errorStateMatcher]="member.matchers.birthdate"
                                                            mask="00/00/0000"
                                                            [required]="defaultInputConfig.birthdate.required"
                                                        />
                                                        <mat-icon matSuffix>date_range</mat-icon>
                                                        <mat-error
                                                            *ngIf="
                                                                defaultInputConfig.birthdate.required &&
                                                                member.competitorForm.controls.birthdate.hasError('required')
                                                            "
                                                        >
                                                            {{ "REGISTRATION.VALIDATIONS.BIRTHDATE_REQUIRED" | translate }}
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="
                                                                !member.competitorForm.controls.birthdate.hasError('required') &&
                                                                member.competitorForm.controls.birthdate.hasError('invalid')
                                                            "
                                                        >
                                                            {{ "REGISTRATION.VALIDATIONS.DATE_INVALID" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.box.hidden"
                                                    >
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.BOX' | translate }}"
                                                            formControlName="box"
                                                            [matAutocomplete]="autoBox"
                                                            (ngModelChange)="searchBox($event)"
                                                            uppercase
                                                            [required]="defaultInputConfig.box.required"
                                                        />
                                                        <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                                                            <mat-option *ngFor="let box of boxes" [value]="box">
                                                                {{ box.name }}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="member.competitorForm.controls.box.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.BOX_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.address.hidden"
                                                    >
                                                        <input
                                                            matInput
                                                            placeholder="{{ 'REGISTRATION.FORM.CITY' | translate }}"
                                                            formControlName="city"
                                                            [errorStateMatcher]="member.matchers.city"
                                                            (ngModelChange)="searchCity($event)"
                                                            [matAutocomplete]="autoCityMember"
                                                            autocomplete="off"
                                                            uppercase
                                                            [required]="defaultInputConfig.address.required"
                                                        />
                                                        <mat-autocomplete autoActiveFirstOption #autoCityMember="matAutocomplete">
                                                            <mat-option
                                                                *ngFor="let city of cities"
                                                                [value]="city.description | uppercase"
                                                                (onSelectionChange)="citySelected(city)"
                                                            >
                                                                {{ city.description }}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="member.competitorForm.controls.city.hasError('required')">
                                                            {{ "REGISTRATION.VALIDATIONS.CITY_REQUIRED" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field
                                                        fxFlex="0 1 50%"
                                                        fxFlex.xs="0 1 100%"
                                                        class="pl-8 pr-8"
                                                        *ngIf="!defaultInputConfig.tshirt.hidden"
                                                    >
                                                        <mat-select
                                                            placeholder="{{ 'REGISTRATION.FORM.TSHIRT' | translate }}"
                                                            formControlName="tShirt"
                                                            [required]="defaultInputConfig.tshirt.required"
                                                        >
                                                            <mat-option *ngFor="let size of tshirtSize" [value]="size">
                                                                {{ size }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div fxLayout="row wrap">
                                                    <ng-container *ngFor="let customField of customFields; let index = index">
                                                        <custom-field
                                                            [customField]="customField"
                                                            [form]="member.competitorForm"
                                                            [fxFlex]="customFieldInOneColumn(customField) ? '0 1 100%' : '0 1 50%'"
                                                            fxFlex.xs="0 1 100%"
                                                            class="pl-8 pr-8"
                                                        >
                                                        </custom-field>
                                                    </ng-container>
                                                </div>

                                                <div fxFlex="100" fxLayout="row" fxLayoutAlign="end end" fxLayout.xs="column">
                                                    <btn-transparent
                                                        fxFlex="35px"
                                                        materialIcon="delete"
                                                        buttonWidth="35px"
                                                        iconAlign="left"
                                                        (click)="removeMember(i)"
                                                        *ngIf="!isEdit && members.length > 1"
                                                    ></btn-transparent>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <btn-icon-small
                                    icon="add_circle_outline"
                                    iconAlign="left"
                                    (click)="addMember()"
                                    *ngIf="isTeam && members.length < totalMembers"
                                    >{{ "REGISTRATION.ADD_MEMBER" | translate }}</btn-icon-small
                                >
                                <div class="pt-16 pb-8 text-red" *ngIf="errorMembers !== null">
                                    {{ errorMembers }}
                                </div>
                            </div>
                        </div>
                        <!-- END TEAM MEMBERS -->

                        <!-- CHECKBOX -->
                        <div class="pt-24">
                            <mat-checkbox formControlName="regulation" required>
                                {{ "REGISTRATION.REGULATION" | translate }}
                            </mat-checkbox>
                            <!-- <mat-error *ngIf="registrationForm.controls.regulation.hasError('required')">
                {{'REGISTRATION.VALIDATIONS.REGULATION_REQUIRED' | translate}}
              </mat-error> -->
                        </div>
                        <!-- END CHECKBOX -->

                        <!-- NFE -->
                        <span class="p-24" >
                            <div fxLayout="column" class="box-line p-24">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.NAME_COMPLETED' | translate }}"
                                            formControlName="fullNameNfe"
                                            [errorStateMatcher]="matchers.fullNameNfe"
                                            (focus)="nameNfeFocus($event)"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.fullNameNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.FULLNAME_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.EMAIL' | translate }}"
                                            formControlName="emailNfe"
                                            [errorStateMatcher]="matchers.emailNfe"
                                            (focus)="emailNfeFocus($event)"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.emailNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.EMAIL_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="
                                                registrationForm.controls.emailNfe.hasError('email') &&
                                                !registrationForm.controls.emailNfe.hasError('required')
                                            "
                                        >
                                            {{ "REGISTRATION.VALIDATIONS.EMAIL_NFE_INVALID" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.CPF' | translate }}"
                                            formControlName="cpfNfe"
                                            [errorStateMatcher]="matchers.cpfNfe"
                                            (focus)="cpfNfeFocus($event)"
                                            mask="000.000.000-00"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.cpfNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.CPF_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.ADDRESS' | translate }}"
                                            formControlName="addressNfe"
                                            [errorStateMatcher]="matchers.addressNfe"
                                            (ngModelChange)="addressCity($event)"
                                            [matAutocomplete]="autoAddressNfe"
                                            autocomplete="off"
                                            uppercase
                                            required
                                        />
                                        <mat-autocomplete autoActiveFirstOption #autoAddressNfe="matAutocomplete">
                                            <mat-option
                                                *ngFor="let address of addressesNfe"
                                                [value]="address.description | uppercase"
                                                (onSelectionChange)="addressSelected(address)"
                                            >
                                                {{ address.description }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="registrationForm.controls.addressNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.ADDRES_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.ADDRESS_NUMBER' | translate }}"
                                            formControlName="addressNumberNfe"
                                            [errorStateMatcher]="matchers.addressNumberNfe"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.addressNumberNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.ADDRESNUMBER_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.DISTRICT' | translate }}"
                                            formControlName="districtNfe"
                                            [errorStateMatcher]="matchers.districtNfe"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.districtNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.DISTRICT_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.CITY' | translate }}"
                                            formControlName="cityNfe"
                                            [errorStateMatcher]="matchers.cityNfe"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.cityNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.CITY_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.STATE' | translate }}"
                                            formControlName="stateNfe"
                                            [errorStateMatcher]="matchers.stateNfe"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.stateNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.STATE_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'REGISTRATION.FORM.ZIPCODE' | translate }}"
                                            formControlName="zipcodeNfe"
                                            [errorStateMatcher]="matchers.zipcodeNfe"
                                            mask="00000-000"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.zipcodeNfe.hasError('required')">
                                            {{ "REGISTRATION.VALIDATIONS.ZIPCODE_NFE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </span>
                        <!-- NFE END -->

                        <!-- PAYMENTS -->
                        <span *ngIf="lot.finalPrice > 0">
                            <h5>{{ "REGISTRATION.PAYMENT_INFORMATION" | translate }}</h5>
                            <div fxLayout="column" class="box-line p-24">
                                <div fxLayout="row" fxLayoutAlign="start center" id="payments-methods" class="mb-24">
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        class="p-16"
                                        [ngClass]="{ active: paymentMethod === 'credit_card' }"
                                        (click)="paymentMethod = 'credit_card'; selectPaymentMethod()"
                                    >
                                        <img src="./assets/icons/registration/ic-credit-card.svg" class="mr-8" />
                                        {{ "REGISTRATION.CREDIT_CARD" | translate | uppercase }}
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            class="ml-32"
                                        >
                                            <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g
                                                    id="Event---Inscrição-Time"
                                                    transform="translate(-489.000000, -1705.000000)"
                                                    fill="#004CFF"
                                                    fill-rule="nonzero"
                                                >
                                                    <g id="Group-21" transform="translate(205.000000, 1634.000000)">
                                                        <g id="Group-19" transform="translate(20.000000, 55.000000)">
                                                            <g id="Group-15" transform="translate(18.000000, 14.000000)">
                                                                <g id="verified" transform="translate(246.000000, 2.000000)">
                                                                    <path
                                                                        d="M20.4852656,3.5146875 C18.2187656,1.24823438 15.2053125,0 12,0 C8.79464062,0 5.7811875,1.24823438 3.5146875,3.5146875 C1.24823437,5.7811875 0,8.7946875 0,12 C0,15.2053125 1.24823437,18.2187656 3.5146875,20.4852656 C5.7811875,22.7517656 8.7946875,24 12,24 C15.2053125,24 18.2187656,22.7517656 20.4852656,20.4853125 C22.7517656,18.2187656 24,15.2053125 24,12 C24,8.7946875 22.7517656,5.78123438 20.4852656,3.5146875 Z M18.6984172,8.43996374 L10.8630775,16.7994987 C10.7026629,16.9706441 10.4924098,17.0561875 10.2822114,17.0561875 C10.0719582,17.0561875 9.86170504,16.9706441 9.70129047,16.7994987 L5.30158279,12.1055356 C4.98075365,11.7633034 4.98075365,11.2083812 5.30158279,10.8660321 C5.62235715,10.5237414 6.14254068,10.5237414 6.46336982,10.8660321 L10.2822114,14.9402726 L17.5366302,7.20046018 C17.8574045,6.85816952 18.3775881,6.85816952 18.6984172,7.20046018 C19.0192463,7.54275085 19.0192463,8.09767308 18.6984172,8.43996374 Z"
                                                                        id="Shape"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                    <button
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        class="p-16"
                                        [ngClass]="{ active: paymentMethod === 'boleto' }"
                                        (click)="paymentMethod = 'boleto'; selectPaymentMethod()"
                                        *ngIf="event.paymentMethodsStrings.includes('boleto') && enabledBoleto"
                                    >
                                        <img src="./assets/icons/registration/ic-boleto.svg" class="mr-8" />
                                        {{ "REGISTRATION.BOLETO" | translate | uppercase }}
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            class="ml-32"
                                        >
                                            <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g
                                                    id="Event---Inscrição-Time"
                                                    transform="translate(-489.000000, -1705.000000)"
                                                    fill="#004CFF"
                                                    fill-rule="nonzero"
                                                >
                                                    <g id="Group-21" transform="translate(205.000000, 1634.000000)">
                                                        <g id="Group-19" transform="translate(20.000000, 55.000000)">
                                                            <g id="Group-15" transform="translate(18.000000, 14.000000)">
                                                                <g id="verified" transform="translate(246.000000, 2.000000)">
                                                                    <path
                                                                        d="M20.4852656,3.5146875 C18.2187656,1.24823438 15.2053125,0 12,0 C8.79464062,0 5.7811875,1.24823438 3.5146875,3.5146875 C1.24823437,5.7811875 0,8.7946875 0,12 C0,15.2053125 1.24823437,18.2187656 3.5146875,20.4852656 C5.7811875,22.7517656 8.7946875,24 12,24 C15.2053125,24 18.2187656,22.7517656 20.4852656,20.4853125 C22.7517656,18.2187656 24,15.2053125 24,12 C24,8.7946875 22.7517656,5.78123438 20.4852656,3.5146875 Z M18.6984172,8.43996374 L10.8630775,16.7994987 C10.7026629,16.9706441 10.4924098,17.0561875 10.2822114,17.0561875 C10.0719582,17.0561875 9.86170504,16.9706441 9.70129047,16.7994987 L5.30158279,12.1055356 C4.98075365,11.7633034 4.98075365,11.2083812 5.30158279,10.8660321 C5.62235715,10.5237414 6.14254068,10.5237414 6.46336982,10.8660321 L10.2822114,14.9402726 L17.5366302,7.20046018 C17.8574045,6.85816952 18.3775881,6.85816952 18.6984172,7.20046018 C19.0192463,7.54275085 19.0192463,8.09767308 18.6984172,8.43996374 Z"
                                                                        id="Shape"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>

                                <div fxLayout="column" *ngIf="paymentMethod === 'credit_card'">
                                    <div
                                        fxLayout="row"
                                        fxLayout.xs="column"
                                        fxLayoutAlign="start start"
                                        fxLayoutAlign.xs="start stretch"
                                        *ngIf="installments.length > 1"
                                    >
                                        <mat-form-field fxFlex="30" fxFlex.xs="100" class="pr-8">
                                            <mat-select
                                                placeholder="{{ 'REGISTRATION.FORM.INSTALLMENTS' | translate }}"
                                                formControlName="installments"
                                            >
                                                <mat-option *ngFor="let installment of installments" [value]="installment">
                                                    {{ installment }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start stretch">
                                        <mat-form-field
                                            fxFlex="50"
                                            fxFlex.xs="100"
                                            class="pr-8"
                                            [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                        >
                                            <input
                                                matInput
                                                type="tel"
                                                placeholder="{{ 'REGISTRATION.FORM.CARD_NUMBER' | translate }}"
                                                formControlName="creditCard"
                                                [errorStateMatcher]="matchers.creditCard"
                                                [mask]="maskCard"
                                                required
                                            />
                                            <img
                                                matSuffix
                                                src="./assets/icons/card/ic-{{ cardType }}.svg"
                                                *ngIf="
                                                    cardType !== '' &&
                                                    cardType !== 'unknown' &&
                                                    !registrationForm.controls.creditCard.hasError('invalid')
                                                "
                                                height="14"
                                            />
                                            <mat-error *ngIf="registrationForm.controls.creditCard.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.CREDIT_CARD_REQUIRED" | translate }}
                                            </mat-error>
                                            <mat-error *ngIf="registrationForm.controls.creditCard.hasError('invalid')">
                                                {{ "REGISTRATION.VALIDATIONS.CREDIT_CARD_INVALID" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field
                                            fxFlex="25"
                                            fxFlex.xs="100"
                                            class="pl-8 pr-8"
                                            [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                        >
                                            <mat-label>{{ "REGISTRATION.FORM.EXPIRATION_DATE" | translate }}</mat-label>
                                            <input
                                                matInput
                                                placeholder="mm/aa"
                                                formControlName="expirationCard"
                                                [errorStateMatcher]="matchers.expirationCard"
                                                mask="00/00"
                                                required
                                            />
                                            <mat-icon matSuffix>date_range</mat-icon>
                                            <mat-error *ngIf="registrationForm.controls.expirationCard.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.EXPIRATION_REQUIRED" | translate }}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="
                                                    !registrationForm.controls.expirationCard.hasError('required') &&
                                                    registrationForm.controls.expirationCard.hasError('invalid')
                                                "
                                            >
                                                {{ "REGISTRATION.VALIDATIONS.DATE_INVALID" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field
                                            fxFlex="25"
                                            fxFlex.xs="100"
                                            class="pl-8"
                                            [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                        >
                                            <input
                                                matInput
                                                maxlength="4"
                                                placeholder="{{ 'REGISTRATION.FORM.CVV' | translate }}"
                                                formControlName="cvv"
                                                [errorStateMatcher]="matchers.cvv"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.cvv.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.CVV_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="column">
                                    <div
                                        fxLayout="row"
                                        fxLayout.xs="column"
                                        fxLayoutAlign="start start"
                                        fxLayoutAlign.xs="start stretch"
                                        class="mt-4"
                                    >
                                        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.HOLD_NAME_BANK_SLIP' | translate }}"
                                                formControlName="holdName"
                                                [errorStateMatcher]="matchers.holdName"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.holdName.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.HOLD_NAME_BANK_SLIP_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8" *ngIf="paymentMethod === 'boleto'">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.CPF' | translate }}"
                                                formControlName="cpfCard"
                                                [errorStateMatcher]="matchers.cpfCard"
                                                mask="000.000.000-00"
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.cpfCard.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.HOLD_CPF_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <ng-container *ngIf="paymentMethod === 'boleto' && event.default_gateway_id === 2">
                                    <div
                                        fxLayout="row"
                                        fxLayout.xs="column"
                                        fxLayoutAlign="start start"
                                        fxLayoutAlign.xs="start stretch"
                                        class="mt-4"
                                    >
                                        <mat-form-field fxFlex="30" fxFlex.xs="100" class="pr-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.CEP' | translate }}"
                                                formControlName="cep"
                                                mask="00000-000"
                                                [errorStateMatcher]="matchers.cep"
                                                autocomplete="off"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.cep.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.CEP_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div
                                        fxLayout="row"
                                        fxLayout.xs="column"
                                        fxLayoutAlign="start start"
                                        fxLayoutAlign.xs="start stretch"
                                        class="mt-4"
                                    >
                                        <mat-form-field fxFlex="80" fxFlex.xs="100" class="pr-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.ADDRESS' | translate }}"
                                                formControlName="address"
                                                [errorStateMatcher]="matchers.address"
                                                autocomplete="off"
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.address.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.ADDRESS_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="20" fxFlex.xs="100" class="pl-8">
                                            <input
                                                #addressNumber
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.ADDRESS_NUMBER' | translate }}"
                                                formControlName="addressNumber"
                                                [errorStateMatcher]="matchers.addressNumber"
                                                autocomplete="off"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.addressNumber.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.ADDRESS_NUMBER_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div
                                        fxLayout="row"
                                        fxLayout.xs="column"
                                        fxLayoutAlign="start start"
                                        fxLayoutAlign.xs="start stretch"
                                        class="mt-4"
                                    >
                                        <mat-form-field fxFlex="40" fxFlex.xs="100" class="pr-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.NEIGHBORHOOD' | translate }}"
                                                formControlName="neighborhood"
                                                [errorStateMatcher]="matchers.neighborhood"
                                                autocomplete="off"
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.neighborhood.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.NEIGHBORHOOD_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="40" fxFlex.xs="100" class="pl-8 pr-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.CITY' | translate }}"
                                                formControlName="cityBoleto"
                                                [errorStateMatcher]="matchers.cityBoleto"
                                                autocomplete="off"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.cityBoleto.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.CITY_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="20" fxFlex.xs="100" class="pl-8">
                                            <input
                                                matInput
                                                placeholder="{{ 'REGISTRATION.FORM.STATE' | translate }}"
                                                formControlName="state"
                                                [errorStateMatcher]="matchers.state"
                                                autocomplete="off"
                                                uppercase
                                                required
                                            />
                                            <mat-error *ngIf="registrationForm.controls.state.hasError('required')">
                                                {{ "REGISTRATION.VALIDATIONS.STATE_REQUIRED" | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </div>
                        </span>
                        <!-- END PAYMENTS -->

                        <div class="pt-16 pb-8 text-red" *ngIf="requestErrors !== null">
                            {{ requestErrors }}
                        </div>

                        <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                            <btn
                                [loading]="isLoading"
                                *ngIf="!hideSubscription"
                                [buttonDisabled]="registrationForm.invalid"
                                (click)="prepareSubscription()"
                                >FINALIZAR E EFETUAR PAGAMENTO</btn
                            >
                            <label-x class="mr-8" type="danger" *ngIf="hideSubscription">
                                {{ "REGISTRATION.ERRORS.SUBSCRIBE_NOT_PERMITTED" | translate }}
                            </label-x>
                        </div>
                    </div>
                </form>
            </div>
            <!-- End Card -->
        </div>
    </div>
</div>
