<div *ngIf="isLoading" class="p-64" fxLayoutAlign="center center">
  <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <div class="page-layout blank pb-64">
    <div class="p-32">
      <div class="content">
        <btn (click)="doKyc()" [buttonDisabled]="!KycButton" class="mb-8" fxLayoutAlign="end center"> REALIZAR PROVA DE
          VIDA
        </btn>
        <div [ngClass.gt-sm]="'mb-0'" class="card auto-width mb-32">
          <form [formGroup]="bankAccountForm" autocomplete="off" name="bankAccountForm" novalidate>
            <div class="p-32 pt-16">
              <div class="row">
                <mat-form-field class="mt-8 " fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.TYPE' | translate }}</mat-label>
                  <mat-select (selectionChange)="changeOwnerType($event.value)" formControlName="owner_type">
                    <mat-option *ngFor="let type of ownerTypes" [value]="type.value">
                      {{ type.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="p-32 pt-16">
              <h2>{{ 'BANK_ACCOUNT.OWNER_DATA' | translate | uppercase }}</h2>
              <div class="row" fxLayout="row" fxLayout.xs="column">
                <mat-form-field class="mt-8 mr-16" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.NAME' | translate }}</mat-label>
                  <input formControlName="owner_name" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.EMAIL' | translate }}</mat-label>
                  <input formControlName="owner_email" matInput/>
                </mat-form-field>
              </div>

              <div class="row" fxLayout="row" fxLayout.xs="column">
                <mat-form-field *ngIf="!isCompany"  class="mt-8 mr-16" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.CPF' | translate }}</mat-label>
                  <input [mask]="'000.000.000-00'" formControlName="owner_cpf" matInput/>
                </mat-form-field>
                <mat-form-field *ngIf="isCompany" class="mt-8 mr-16" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.CNPJ' | translate }}</mat-label>
                  <input [mask]="'00.000.000/0000-00'" formControlName="owner_cnpj" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.BIRTH_DATE' | translate }}</mat-label>
                  <input [mask]="'00/00/0000'" formControlName="owner_birthdate" matInput/>
                </mat-form-field>
              </div>

              <div class="row" fxLayout="row" fxLayout.xs="column">
                <mat-form-field class="mt-8 mr-16" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.MONTHLY_INCOME' | translate }}</mat-label>
                  <input decimalMarker=","
                         formControlName="owner_monthly_income"
                         mask="separator.2"
                         matInput
                         thousandSeparator="."
                         type="text"/>

                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.PROFESSIONAL_OCCUPATION' | translate }}</mat-label>
                  <input formControlName="owner_professional_occupation" matInput/>
                </mat-form-field>
              </div>

              <div class="row" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                <mat-form-field class="mt-8" fxFlex="45" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.PHONE' | translate }}</mat-label>
                  <input
                    formControlName="owner_phone"
                    mask="(00) 00000-0000"
                    matInput
                    type="tel"/>
                </mat-form-field>
              </div>
            </div>
            
      
            <div *ngIf="!isCompany" class="p-32 pt-16">
              <h2>{{ 'BANK_ACCOUNT.ADDRESS' | translate | uppercase }}</h2>
            <div class="row">
              <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.ZIP_CODE' | translate }}</mat-label>
                <input formControlName="zip_code" matInput/>
              </mat-form-field>
              <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.ADDRESS' | translate }}</mat-label>
                <input formControlName="address" matInput/>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.NUMBER' | translate }}</mat-label>
                <input formControlName="number" matInput/>
              </mat-form-field>
              <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.COMPLEMENT' | translate }}</mat-label>
                <input formControlName="complement" matInput/>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.NEIGHBORHOOD' | translate }}</mat-label>
                <input formControlName="neighborhood" matInput/>
              </mat-form-field>
              <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.CITY' | translate }}</mat-label>
                <input formControlName="city" matInput/>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.STATE' | translate }}</mat-label>
                <input formControlName="state" matInput maxlength="2"/>
              </mat-form-field>
              <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                <mat-label>{{ 'BANK_ACCOUNT.REFERENCE_POINT' | translate }}</mat-label>
                <input formControlName="reference_point" matInput/>
              </mat-form-field>
            </div>
            </div>
 

            <div class="p-32 pt-16" *ngIf="isCompany">
              <h2 *ngIf="!isCompany">{{ 'BANK_ACCOUNT.ADDRESS' | translate | uppercase }}</h2>
              <h2>{{ 'BANK_ACCOUNT.COMPANY_DATA' | translate | uppercase }}</h2>

              <div>
                <div class="row">
                  <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.TRADE_NAME' | translate }}</mat-label>
                    <input formControlName="trade_name" matInput/>
                  </mat-form-field>
                  <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.COMPANY_NAME' | translate }}</mat-label>
                    <input formControlName="company_name" matInput/>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.EMAIL' | translate }}</mat-label>
                    <input formControlName="company_email" matInput/>
                  </mat-form-field>
                  <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.CPF' | translate }}</mat-label>
                    <input [mask]="'000.000.000-00'" formControlName="company_cpf" matInput/>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.PHONE' | translate }}</mat-label>
                    <input
                      formControlName="phone"
                      mask="(00) 00000-0000"
                      matInput
                      type="tel"/>
                  </mat-form-field>
                  <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                    <mat-label>{{ 'BANK_ACCOUNT.ANNUAL_REVENUE' | translate }}</mat-label>
                    <input decimalMarker=","
                           formControlName="annual_revenue"
                           mask="separator.2"
                           matInput
                           thousandSeparator="."
                           type="text"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ZIP_CODE' | translate }}</mat-label>
                  <input formControlName="company_zip_code" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ADDRESS' | translate }}</mat-label>
                  <input formControlName="company_address" matInput/>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.NUMBER' | translate }}</mat-label>
                  <input formControlName="company_number" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.COMPLEMENT' | translate }}</mat-label>
                  <input formControlName="company_complement" matInput/>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.NEIGHBORHOOD' | translate }}</mat-label>
                  <input formControlName="company_neighborhood" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.CITY' | translate }}</mat-label>
                  <input formControlName="company_city" matInput/>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.STATE' | translate }}</mat-label>
                  <input formControlName="state" matInput maxlength="2"/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.REFERENCE_POINT' | translate }}</mat-label>
                  <input formControlName="company_reference_point" matInput/>
                </mat-form-field>
              </div>
            </div>

            <div class="p-32 pt-16">
              <h2>{{ 'BANK_ACCOUNT.ACCOUNT_DATA' | translate | uppercase }}</h2>

              <div class="row">
                <mat-form-field class="mt-8" fxFlex="100" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ACCOUNT_NAME' | translate }}</mat-label>
                  <input formControlName="account_name" matInput maxlength="30"/>
                </mat-form-field>
              </div>

              <div class="row">

                <mat-form-field class="mt-8 mr-16" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.BANK' | translate }}</mat-label>
                  <input (ngModelChange)="searchBank($event)" [matAutocomplete]="autoBank" autocomplete="off"
                         formControlName="bank"
                         matInput placeholder="{{ 'BANK_ACCOUNT.BANK' | translate }}">
                  <mat-autocomplete #autoBank="matAutocomplete"  [displayWith]="displayFnBank" autoActiveFirstOption
                                    panelWidth="auto"
                                    xPosition="before">
                    <mat-option *ngFor="let bank of banks" [value]="bank">
                      {{ bank.code }} - {{ bank.title }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="mt-8" fxFlex="50" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ACCOUNT_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="account_type">
                    <mat-option [value]="'checking'">{{ 'BANK_ACCOUNT.CHECKING' | translate }}</mat-option>
                    <mat-option [value]="'savings'">{{ 'BANK_ACCOUNT.SAVINGS' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>


              <div class="row">
                <mat-form-field class="mt-8 mr-16" fxFlex="25" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.AGENCY' | translate }}</mat-label>
                  <input formControlName="agency" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8 mr-16" fxFlex="25" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.AGENCY_DIGIT' | translate }}</mat-label>
                  <input formControlName="agency_digit" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8 mr-16" fxFlex="25" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ACCOUNT_NUMBER' | translate }}</mat-label>
                  <input formControlName="account_number" matInput/>
                </mat-form-field>
                <mat-form-field class="mt-8" fxFlex="25" fxFlex.xs="100">
                  <mat-label>{{ 'BANK_ACCOUNT.ACCOUNT_DIGIT' | translate }}</mat-label>
                  <input formControlName="account_digit" matInput/>
                </mat-form-field>
              </div>
            </div>


          </form>

          <div class="p-32 pt-16">
            <div class="mb-0 mt-8" fxLayoutAlign="end center">
              <btn (click)="saveAdmin()" [btnLoading]="true"
                   [loading]="isLoading">
                {{ 'BANK_ACCOUNT.SAVE' | translate | uppercase }}
              </btn>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</ng-container>
