<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div [ngClass.gt-sm]="'mb-0'" class="card auto-width mb-32">
        <div class="p-32 pt-16">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h3>{{ eventName }}</h3>
            <label-x class="ml-8" type="{{ (event.published) ? 'success' : 'warning'}}">
              {{ (event.published) ? ('SETTINGS.EVENT_STATUS.PUBLISHED' | translate | uppercase) : ('SETTINGS.EVENT_STATUS.NOT_PUBLISHED' | translate | uppercase) }}
            </label-x>
          </div>
          <form [formGroup]="eventForm" autocomplete="off" name="eventForm" novalidate>
            <div fxLayout="column" fxLayoutAlign="stretch stretch">
              <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start"
                   fxLayoutAlign.xs="start stretch">
                <!-- FIRST COLUMN -->
                <div fxFlex="50" fxFlex.xs="auto" fxLayout="column" fxLayoutAlign="space-evenly stretch"
                     fxLayoutAlign.xs="start stretch">
                  <mat-form-field>
                    <input #nameEventInput [errorStateMatcher]="eventMatchers.name" focus
                           formControlName="name" matInput placeholder="{{ 'SETTINGS.FIELDS.NAME' | translate }}" required uppercase>
                    <mat-error *ngIf="eventForm.controls.name.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.NAME_REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      [errorStateMatcher]="eventMatchers.address"
                      [matAutocomplete]="autoAddress" autocomplete="off"
                      formControlName="address" matInput
                      placeholder="{{ 'SETTINGS.FIELDS.ADDRESS' | translate }}" required>

                    <mat-autocomplete #autoAddress="matAutocomplete" autoActiveFirstOption xPosition="before">
                      <mat-option (onSelectionChange)="addressSelected(city)" *ngFor="let city of cities"
                                  [value]="city.description">
                        {{ city.description }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="eventForm.controls.address.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.ADDRESS_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="mb-8">
                    <input [errorStateMatcher]="eventMatchers.location" formControlName="location"
                           matInput placeholder="{{ 'SETTINGS.FIELDS.LOCATION' | translate }}" required>
                    <mat-error *ngIf="eventForm.controls.location.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.LOCATION_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="mb-8">
                    <input [errorStateMatcher]="eventMatchers.timezone" [matAutocomplete]="timezone" [readonly]="!userMe.admin"
                           formControlName="timezone" matInput
                           placeholder="{{ 'SETTINGS.FIELDS.TIMEZONE' | translate }}" required>
                    <mat-autocomplete #timezone="matAutocomplete" [displayWith]="displayFnTimezone">
                      <mat-option *ngFor="let timezone of filterdTimezoneData | async" [value]="timezone">
                        {{ timezone.text }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="eventForm.controls.timezone.hasError('required') || timezoneRequired">
                      {{ 'SETTINGS.VALIDATIONS.TIMEZONE_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <div class="mb-8" fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-form-field class="inputDate" fxFlex="65">
                      <input [dropSpecialCharacters]="false" [errorStateMatcher]="eventMatchers.dateStart"
                             [readonly]="!userMe.admin" formControlName="dateStart"
                             mask="00/00/0000" matInput
                             placeholder="{{'SETTINGS.FIELDS.DATE_START' | translate}}" required>
                      <!-- (ngModelChange)="verifyDate($event)" -->
                      <mat-icon matSuffix>date_range</mat-icon>
                      <mat-error *ngIf="eventForm.controls.dateStart.hasError('required')">
                        {{'SETTINGS.VALIDATIONS.DATE_START_REQUIRED' | translate}}
                      </mat-error>
                      <mat-error
                        *ngIf="!eventForm.controls.dateStart.hasError('required') && eventForm.controls.dateStart.hasError('invalid')">
                        {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                      </mat-error>
                    </mat-form-field>

                    <select-time
                      #pickerTimeStart
                      (inputValue)="startTimeChange($event)"
                      [readonly]="!userMe.admin"
                      [required]="true"
                      [value]="timeStart"
                      class="pl-12 hour"
                      fxFlex="35"
                      label="{{'SETTINGS.FIELDS.TIME_START' | translate }}"></select-time>
                  </div>

                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-form-field class="inputDate" fxFlex="65">
                      <input [dropSpecialCharacters]="false" [errorStateMatcher]="eventMatchers.dateEnd"
                             [readonly]="!userMe.admin" formControlName="dateEnd"
                             mask="00/00/0000" matInput
                             placeholder="{{'SETTINGS.FIELDS.DATE_END' | translate}}" required>
                      <!-- (ngModelChange)="verifyDate($event)" -->
                      <mat-icon matSuffix>date_range</mat-icon>
                      <mat-error *ngIf="eventForm.controls.dateEnd.hasError('required')">
                        {{'SETTINGS.VALIDATIONS.DATE_END_REQUIRED' | translate}}
                      </mat-error>
                      <mat-error
                        *ngIf="!eventForm.controls.dateEnd.hasError('required') && eventForm.controls.dateEnd.hasError('invalid')">
                        {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                      </mat-error>
                    </mat-form-field>
                    <select-time
                      #pickerTimeEnd
                      (inputValue)="endTimeChange($event)"
                      [readonly]="!userMe.admin"
                      [required]="true"
                      [value]="timeEnd"
                      class="pl-12 hour"
                      fxFlex="35"
                      label="{{'SETTINGS.FIELDS.TIME_END' | translate }}"></select-time>
                  </div>
                </div>

                <!-- SECOND COLUMN -->
                <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-around start">
                  <agm-map
                    [latitude]="lat" [longitude]="lng" [scrollwheel]="false"
                    [streetViewControl]="false" [zoom]="15"
                    fxFlex="100" ngClass="ml-16 gmaps" ngClass.xs="ml-0 gmaps">
                    <agm-marker *ngIf="isShowMarker" [latitude]="lat" [longitude]="lng"></agm-marker>
                  </agm-map>
                </div>
              </div>


              <h3>{{ 'SETTINGS.EVENT_PAGE' | translate }}</h3>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.xs="start stretch">
                <mat-form-field fxFlex="50" fxFlex.xs="100">
                  <input [errorStateMatcher]="eventMatchers.urlEvent" formControlName="urlEvent" matInput
                         placeholder="{{ 'SETTINGS.FIELDS.EVENT_URL' | translate }}" required>
                  <span class="url-suffix" matSuffix>.crossx.com.br</span>
                  <mat-error *ngIf="eventForm.controls.urlEvent.hasError('required')">
                    {{ 'SETTINGS.VALIDATIONS.URL_EVENT_REQUIRED' | translate}}
                  </mat-error>
                  <mat-error
                    *ngIf="!eventForm.controls.urlEvent.hasError('required') && eventForm.controls.urlEvent.hasError('minlength')">
                    {{ 'SETTINGS.VALIDATIONS.URL_EVENT_MINLENGTH' | translate:{count: eventForm.controls.urlEvent.errors.minlength.requiredLength} }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="images-select" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                <div fxFlex="50" fxLayout="column">
                  <h4>{{ 'SETTINGS.IMAGES.BRAND' | translate }}</h4>
                  <div class="wrap" fxLayout="row" fxLayoutAlign="start start">
                    <div class="mr-8 image-box" fxFlex="126px" fxLayoutAlign="center center">
                      <img *ngIf="(brandUrl !== ''); else placeholderBrand" [src]="brandUrl" class="image">
                      <ng-template #placeholderBrand><img class="mt-32 mb-32"
                                                          src="./assets/icons/placeholder/ic-picture.svg"></ng-template>
                    </div>
                    <div class="pr-16" fxFlex="auto">
                      <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_BRAND' | translate }}"></span><br>
                      <input (change)="readUrl($event, 'brand')" accept=".jpg, .jpeg, .png" class="inputfile" id="file" name="file"
                             type="file"/>
                      <label class="select-btn" class="btn mt-8"
                             for="file">{{ 'SETTINGS.IMAGES.SELECT_BRAND' | translate }}</label>
                    </div>
                  </div>
                </div>

                <div fxFlex="50" fxLayout="column">
                  <h4>{{ 'SETTINGS.IMAGES.HEADER' | translate }}</h4>
                  <div class="wrap" fxLayout="row" fxLayoutAlign="start start">
                    <div class="mr-8 image-box" fxFlex="182px" fxLayoutAlign="center center">
                      <img *ngIf="(headerUrl !== ''); else placeholderHeader" [src]="headerUrl" class="image">
                      <ng-template #placeholderHeader>
                        <img class="mt-8 mb-8" src="./assets/icons/placeholder/ic-picture.svg">
                      </ng-template>
                    </div>
                    <div fxFlex="auto" fxLayout="column">
                      <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_HEADER' | translate }}"></span>
                      <input (change)="readUrl($event, 'header')" accept=".jpg, .jpeg, .png" class="inputfile" id="file-header"
                             name="file-header" type="file"/>
                      <label class="select-btn" class="btn mt-8"
                             for="file-header">{{ 'SETTINGS.IMAGES.SELECT_IMAGE' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-32 mb-32" fxLayout="column" fxLayoutAlign="start start">
                <h4>{{ 'SETTINGS.FIELDS.DESCRIPTION' | translate }}</h4>
                <quill #quill
                       (valueChange)="onValueChange($event)"
                       [config]="configEditor">
                  <div quillToolbar></div><!-- Optional custom toolbar -->
                  <div quillContent></div><!-- Optional pre-filled content -->
                </quill>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
                <div class="pr-16" fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
                  <mat-form-field *ngIf="event.doc_rule === null" class="pr-16" fxFlex="auto">
                    <input [errorStateMatcher]="eventMatchers.regulation" [value]="regulationFileName"
                           formControlName="regulation" matInput
                           placeholder="{{ 'SETTINGS.FIELDS.REGULATION' | translate }}">
                    <mat-error *ngIf="eventForm.controls.regulation.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.REGULATION_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>
                  <a *ngIf="event.doc_rule && event.doc_term !== 'remove'" fxFlex="60" href="{{ event.doc_rule }}"
                     target="_blank">{{ 'SETTINGS.FIELDS.SEE_REGULATION' | translate }}</a>
                  <input (change)="readUrl($event, 'regulation')" accept=".pdf" class="inputfile" id="file-regulation"
                         name="file-regulation" type="file"/>
                  <label *ngIf="!event.doc_rule || event.doc_rule === 'remove'" class="btn mt-8" for="file-regulation"
                         fxFlex="auto">{{ 'SETTINGS.SELECT' | translate }}</label>
                  <label (click)="removeDoc('regulation', event)" *ngIf="event.doc_rule && event.doc_term !== 'remove'" class="btn mt-8"
                         fxFlex="auto">{{ 'SETTINGS.REMOVE' | translate }}</label>
                </div>

                <div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around">
                  <mat-form-field *ngIf="event.doc_term === null" class="pr-16" fxFlex="auto">
                    <input [errorStateMatcher]="eventMatchers.terms" [value]="termsFileName" disabled
                           formControlName="terms" matInput placeholder="{{ 'SETTINGS.FIELDS.TERMS' | translate }}">
                    <mat-error *ngIf="eventForm.controls.terms.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.TERMS_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <a *ngIf="event.doc_term && event.doc_term !== 'remove'" fxFlex="60" href="{{ event.doc_term }}"
                     target="_blank">{{ 'SETTINGS.FIELDS.SEE_TERMS' |
                    translate }}</a>
                  <input (change)="readUrl($event, 'terms')" accept=".pdf" class="inputfile" id="file-terms"
                         name="file-terms" type="file"/>
                  <label *ngIf="!event.doc_term || event.doc_term === 'remove'" class="btn mt-8" for="file-terms"
                         fxFlex="auto">{{ 'SETTINGS.SELECT' | translate }}</label>
                  <label (click)="removeDoc('terms', event)" *ngIf="event.doc_term && event.doc_term !== 'remove'" class="btn mt-8"
                         fxFlex="auto">{{ 'SETTINGS.REMOVE' | translate }}</label>
                </div>
              </div>

              <div class="mt-32" fxLayout="row" fxLayoutAlign="end center">
                <btn (click)="publishOrUnpublishEvent()" [btnLoading]="true" [buttonDisabled]="eventForm.invalid && !event.published"
                     [loading]="isPublishLoading" type="grayscale">
                  {{ (event.published ? 'SETTINGS.UNPUBLISH_EVENT' : 'SETTINGS.PUBLISH_EVENT') | translate | uppercase }}
                </btn>
                <btn (click)="updateEventDetail()" [btnLoading]="true" [buttonDisabled]="eventForm.invalid"
                     [loading]="isLoading" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
