import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {locale as english} from './../i18n/en-US';
import {locale as portugues} from './../i18n/pt-BR';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {Subscription as SubscriptionEvent} from 'rxjs';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {config} from '../../../../../core/config/config';
import {TableConfig} from '../../../../../core/components/table-x/table-x.component';
import {SubscriptionService} from '../../../../../core/services/subscription/subscription.service';
import {Subscription} from '../../../../../core/model/subscription';
import {Payment, PaymentStatus} from '../../../../../core/model/payment';
import {Utils} from '../../../../../core/utils';
import {Championship} from '../../../../../core/model/championship';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Invitation, InvitationStatus} from '../../../../../core/model/invitation.model';
import {Errors} from '../../../../../core/model/errors';
import {InvitationService} from '../../../../../core/services/invitation/invitation.service';
import {CreditCardValidate} from '../../../../../core/utils/custom-validators';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {Event} from '../../../../../core/model/event';
import {GetCardMaskType, GetCreditCardType} from '../../../../../core/utils/credit-card';
import {ModalConfirmPaymentComponent} from '../../event-page/registration/modal-confirm-payment/modal-confirm-payment.component';
import {PagarmeService} from '../../../../../core/services/pagarme-services/pagarme.service';
import {PaymentService} from '../../../../../core/services/payment/payment.service';
import * as moment from 'moment';
import {CepService} from '../../../../../core/services/cep/cep.service';
import {Gateways} from '../../../../../core/enum/gateways';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {


  @ViewChild('addressNumber') addressNumberField: ElementRef;
  // translate
  translate: any;
  //Event
  public event: Event = null;
  public championship: Championship = null;
  //Subscription
  public subscription: Subscription = null;
  public payments: Payment[] = null;
  //Payments
  public registrationForm: FormGroup;
  public matchers: any;
  public paymentMethod: string = 'credit_card';
  public maskCard: string = '0000 0000 0000 0000 999';
  public cardType: string = '';
  public enabledBoleto: boolean = true;
  public installments: string[] = [];
  public isShowPaymentForm: boolean = false;
  public isLoadingForm: boolean = false;
  public isLoadingPayment: boolean = false;
  public isLoading: boolean = false;
  public isCancelSubscription: boolean = false;
  timeOutCepSearch;
  //Table
  public tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };
  private subscriptions: SubscriptionEvent = new SubscriptionEvent();
  private subscriptionRequest: SubscriptionEvent = new SubscriptionEvent();
  private subscriptionFetch = null;
  //Championship
  private championshipId: number = null;

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private invitationService: InvitationService,
    private pagarmeService: PagarmeService,
    private paymentService: PaymentService,
    private cepService: CepService
  ) {
  }

  ngOnInit() {
    this.getEvent();
    this.registerOnTranslatePayment();
    this.registerOnRoute();
    this.configForm();
    // this.verifyScreenShow();
  }

  //Subscription
  public prepareSubscription() {
    // Prevent multiple click
    if (this.isLoading) {
      return;
    }

    // validate all form fields
    if (!Utils.formsVerifyIsValid([this.registrationForm])) {
      Utils.formSetHasError([this.registrationForm]);
      return;
    }

    this.createSubscription();
  }

  preparePayment(subscription: Subscription) {
    if (this.paymentMethod === 'credit_card') {
      const expirationDate = moment(this.registrationForm.value.expirationCard, 'MM/YY');

      const card = {
        card_number: this.registrationForm.value.creditCard,
        card_holder_name: this.registrationForm.value.holdName,
        card_expiration_date: this.registrationForm.value.expirationCard,
        card_cvv: this.registrationForm.value.cvv
      };

      return this.pagarmeService.generateCardHash(card)
        .then(card_hash => {

          const installments = this.registrationForm.value.installments.replace('X', '');
          const parameters = {
            subscription_id: subscription.id,
            payment_method: this.paymentMethod,
            card_hash: card_hash,
            card_number: this.registrationForm.value.creditCard,
            expiration_month: expirationDate.format('MM'),
            expiration_year: expirationDate.format('YYYY'),
            security_code: this.registrationForm.value.cvv,
            installments: installments,
            payment_name: this.registrationForm.value.holdName.toUpperCase(),
            payment_document: this.registrationForm.value.cpfNfe
          };

          this.proccessPayment(parameters);
        })
        .catch(error => {
          console.error(error);
        });
    } else if (this.paymentMethod === 'boleto') {
      const parameters = {
        subscription_id: subscription.id,
        payment_method: this.paymentMethod,
        payment_name: this.registrationForm.value.holdName,
        payment_document: this.registrationForm.value.cpfNfe
      };

      if (this.event.default_gateway_id === Gateways.ZOOP) {
        parameters['payment_address'] = {
          line1: `${this.registrationForm.get('address').value}, ${this.registrationForm.get('addressNumber').value}`,
          line2: '',
          neighborhood: this.registrationForm.get('neighborhood').value,
          city: this.registrationForm.get('city').value,
          state: this.registrationForm.get('state').value,
          postal_code: this.registrationForm.get('cep').value,
          country_code: 'BR',
        };
      }


      this.proccessPayment(parameters);
    }
  }

  proccessPayment(parameters: any) {
    // this.errorInPayment = false
    this.paymentService.makePayment(parameters)
      .subscribe(
        result => {
          this.isLoadingForm = false;
          this.championship.is_invitation = false;
          this.championship.invitation = null;
          this.isShowPaymentForm = false;
          this.subscription = null;
          this.payments = null;
          const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          dialogRef.componentInstance.subscriptionPayment = result;
          dialogRef.componentInstance.type = 'success';

          dialogRef.afterClosed().subscribe(result => {

          });

          if (result.status === PaymentStatus.APPROVED) {
            this.updateChampionshipWithPaidInvitation();
          }
          this.paymentMethod = 'credit_card';
          this.selectPaymentMethod();
          this.verifyScreenShow();

        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          // this.requestErrors = errors.getFullMessages()
          this.isLoadingForm = false;
          // this.errorInPayment = true

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'
          dialogRef.componentInstance.type = 'error';
          dialogRef.componentInstance.titleDialog = this.translate.ERRORS.PAYMENT_TITLE_FAILED;
          dialogRef.componentInstance.dialogContent = errors.getFullMessages();

          dialogRef.afterClosed().subscribe(result => {
          });
        }
      );
  }

  // REFUSE INVITATION
  public refusedInvitation(): void {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });


    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.INVITATION.DIALOG.REFUSED.TITLE;
    this.translateService.get('PAYMENT.INVITATION.DIALOG.REFUSED.DESCRIPTION', {championship_name: this.championship.name}).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.BUTTONS_DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.BUTTONS_DIALOG.CONFIRM.toUpperCase();
    dialogRef.componentInstance.object = this.championship.invitation;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      if (result) {
        dialogRef.componentInstance.confirmLoading = true;
        this.refusedInvitationAPI(this.championship.invitation, dialogRef);
      }
    });
  }

  formatCurrency(value: string, currency: string): string {
    return Utils.formatCurrency(value, currency);
  }

  getPaymentStatus(payment: Payment, type: boolean = false): string {
    switch (payment.statusCode) {
      case PaymentStatus.APPROVED:
        return (!type) ? this.translate.PAYMENT_STATUS.APPROVED : 'success';

      case PaymentStatus.WAITING:
        return (!type) ? this.translate.PAYMENT_STATUS.WAITING : 'normal';

      case PaymentStatus.REFUSED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUSED : 'danger';

      case PaymentStatus.REFUNDED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUNDED : 'purple';

      case PaymentStatus.CHARGEBACK:
        return (!type) ? this.translate.PAYMENT_STATUS.CHARGEBACK : 'purple';

      default:
        return '';
    }
  }

  getCardBrandIcon(payment: Payment): string {
    return Utils.getCardBrandIcon(payment.cc_brand);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public selectPaymentMethod(): void {
    if (this.paymentMethod === 'credit_card') {
      this.registrationForm.controls['creditCard'].setValidators([Validators.required, CreditCardValidate()]);
      this.registrationForm.controls['expirationCard'].setValidators([Validators.required]);
      this.registrationForm.controls['cvv'].setValidators([Validators.required]);
      this.registrationForm.controls['cpfCard'].clearValidators();
      this.registrationForm.controls['cpfCard'].setErrors(null);

      if (this.event.default_gateway_id === Gateways.ZOOP) {
        this.registrationForm.controls['address'].clearValidators();
        this.registrationForm.controls['address'].setErrors(null);
        this.registrationForm.controls['addressNumber'].clearValidators();
        this.registrationForm.controls['addressNumber'].setErrors(null);
        this.registrationForm.controls['neighborhood'].clearValidators();
        this.registrationForm.controls['neighborhood'].setErrors(null);
        this.registrationForm.controls['city'].clearValidators();
        this.registrationForm.controls['city'].setErrors(null);
        this.registrationForm.controls['state'].clearValidators();
        this.registrationForm.controls['state'].setErrors(null);
      }
    } else if (this.paymentMethod === 'boleto') {
      this.registrationForm.controls['creditCard'].clearValidators();
      this.registrationForm.controls['expirationCard'].clearValidators();
      this.registrationForm.controls['cvv'].clearValidators();
      this.registrationForm.controls['creditCard'].setErrors(null);
      this.registrationForm.controls['expirationCard'].setErrors(null);
      this.registrationForm.controls['cvv'].setErrors(null);

      this.registrationForm.controls['cpfCard'].setValidators([Validators.required]);
      if (this.event.default_gateway_id === Gateways.ZOOP) {
        this.registrationForm.controls['address'].setValidators([Validators.required]);
        this.registrationForm.controls['addressNumber'].setValidators([Validators.required]);
        this.registrationForm.controls['neighborhood'].setValidators([Validators.required]);
        this.registrationForm.controls['city'].setValidators([Validators.required]);
        this.registrationForm.controls['state'].setValidators([Validators.required]);
      }
    }

  }

  public seeBoleto(boleto_url: string): void {
    Utils.openInOtherWindow(boleto_url);
  }

  public cancelSubscription(): void {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });


    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.CANCEL_SUBSCRIPTION.TITLE;
    this.translateService.get('PAYMENT.DIALOG.CANCEL_SUBSCRIPTION.DESCRIPTION', {championship_name: this.championship.name}).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.BUTTONS_DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.BUTTONS_DIALOG.CONFIRM.toUpperCase();
    dialogRef.componentInstance.object = this.subscription;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      if (result) {
        dialogRef.componentInstance.confirmLoading = true;
        this.cancelSubscriptionAPI(this.subscription, dialogRef);
      }
    });
  }

  private editAddressNumber(): void {
    this.addressNumberField.nativeElement.focus();
  }

  private resetValues(): void {
    this.subscriptionRequest.remove(this.subscriptionFetch);
    this.subscriptionFetch = null;
    this.subscription = null;
    this.payments = null;
    this.isLoading = false;
    this.registerOnFetchSubscriptionPayment();
    this.verifyScreenShow();
  }

  private createSubscription(): void {

    if (this.championship.is_invitation && this.subscription === null) {
      this.createSubscriptionWithInvitation();
    } else {
      this.isLoadingForm = true;
      this.preparePayment(this.subscription);
    }
  }

  private createSubscriptionWithInvitation(): void {
    const parameters = {
      'lot_id': this.championship.invitation.lot.id
    };


    this.isLoadingForm = true;
    this.invitationService.userAccept(this.championship.invitation.id, parameters)
      .subscribe(
        result => {

          if (this.championship.invitation.lot.finalPrice > 0) {
            this.preparePayment(result);
          } else {
            this.isLoading = false;
            const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, {
              width: '440px'
            });

            dialogRef.componentInstance.dialogConfirm = false;
            dialogRef.componentInstance.cancelButton = 'FECHAR';
            dialogRef.componentInstance.type = 'success';

            dialogRef.afterClosed().subscribe(result => {

            });
          }

        }, err => {
          this.isLoadingForm = false;
          const errors: Errors = new Errors().deserialize((err.error as any));

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          dialogRef.componentInstance.type = 'error';
          dialogRef.componentInstance.titleDialog = this.translate.ERRORS.SUBSCRIBE_TITLE_FAILED;
          dialogRef.componentInstance.dialogContent = errors.getFullMessages();

          dialogRef.afterClosed().subscribe(result => {
          });
        }
      );
  }

  private verifyScreenShow(): void {
    if (!this.championship.is_invitation || (this.championship.invitation.status !== InvitationStatus.PENDING && this.championship.invitation.status !== InvitationStatus.REFUSED)) {
      this.getSubscriptionChampionship();
    }
  }

  private getSubscriptionChampionship(): void {
    this.isLoadingPayment = true;
    this.isLoading = true;
    this.subscriptionService.fetchSubscriptionWithChampionship(this.championship.id, this.championship.competition.id, this.championship.team.id);
  }

  private registerOnFetchSubscriptionPayment(): any {
    this.subscriptionFetch = this.subscriptionService
      .fetchSubscriptionObservable()
      .subscribe((subscription: Subscription) => {
        this.subscription = subscription;
        this.payments = subscription.payment ? [subscription.payment] : [];
        this.isLoadingPayment = false;
        this.isLoading = false;
      }, error => {
        this.isLoadingPayment = false;
        this.isLoading = false;
      });
    this.subscriptionRequest.add(this.subscriptionFetch);
  }

  private refusedInvitationAPI(invitation: Invitation, dialogRef: MatDialogRef<CrossXDialogComponent>) {

    this.invitationService.userRefuse(this.championship.invitation.id)
      .subscribe(
        result => {

          var titleToastr = '';
          this.translateService.get('PAYMENT.INVITATION.TOASTR.REFUSE.DESCRIPTION', {championship_name: this.championship.name}).subscribe((res: string) => {
            titleToastr = res;
          });

          this.toastr.show(
            titleToastr,
            this.translate.INVITATION.TOASTR.REFUSE.TITLE,
            null,
            'success',
          );
          this.championship.invitation.status = InvitationStatus.CANCELLED;
          this.updateChampionshipWithRefusedInvitation();

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }, err => {

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.INVITATION.TOASTR.REFUSE.ERROR,
            null,
            'error',
          );

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }
      );
  }

  private updateChampionshipWithRefusedInvitation(): void {
    var championships = this.eventGuard.getAllChampionships();
    // @ts-ignore
    const championshipActual = championships.find((item) => item.id === this.championship.id);
    championships.splice(championships.indexOf(championshipActual), 1);
    this.eventGuard.updateEventChampionships(championships, false);
  }

  private updateChampionshipWithPaidInvitation(): void {
    let championships = this.eventGuard.getAllChampionships();
    // @ts-ignore
    let championshipActual = championships.find((item) => item.id === this.championship.id);
    championshipActual.is_invitation = false;
    championshipActual.invitation = null;

    this.eventGuard.updateEventChampionships(championships, false);
  }

  private registerOnRoute(): void {

    const subscription = this.activatedRoute.params.subscribe(
      params => {
        this.championshipId = params.championship_id;
        this.championship = new Championship().deserialize(this.eventGuard.getChampionshipPerId(this.championshipId));

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `/user-dashboard/championship/${this.championshipId}/payment`
          }]);
        }

        this.resetValues();
      }
    );

    this.subscriptions.add(subscription);
  }

  private registerOnTranslatePayment(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('PAYMENT')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);

  }

  private configForm(): void {
    this.registrationForm = this.formBuilder.group({
      installments: ['1X'],
      creditCard: [''],
      expirationCard: [''],
      cvv: [''],
      holdName: ['', [Validators.required]],
      cpfCard: [''],
      cep: [''],
      address: [{
        value: '',
        disabled: true
      }],
      addressNumber: [''],
      neighborhood: [{
        value: '',
        disabled: true
      }],
      city: [{
        value: '',
        disabled: true
      }],
      state: [{
        value: '',
        disabled: true
      }]
    });

    this.matchers = {
      creditCard: new FormErrorStateMatcher(),
      installments: new FormErrorStateMatcher(),
      expirationCard: new FormErrorStateMatcher(),
      cvv: new FormErrorStateMatcher(),
      holdName: new FormErrorStateMatcher(),
      cpfCard: new FormErrorStateMatcher(),
      cep: new FormErrorStateMatcher(),
      address: new FormErrorStateMatcher(),
      addressNumber: new FormErrorStateMatcher(),
      neighborhood: new FormErrorStateMatcher(),
      city: new FormErrorStateMatcher(),
      state: new FormErrorStateMatcher()
    };

    this.formInputOnValueChanges();
    this.selectPaymentMethod();

  }

  private formInputOnValueChanges(): void {
    const subscriptionCreditCard = this.registrationForm.get('creditCard').valueChanges.subscribe(value => {
      this.maskCard = GetCardMaskType(GetCreditCardType(value));
      this.cardType = GetCreditCardType(value);
    });

    this.subscriptions.add(subscriptionCreditCard);

    const subscriptionCep = this.registrationForm.get('cep').valueChanges.subscribe(value => {

      if (value.length > 7) {
        const subscription = this.cepService.search(value)
          .subscribe(
            result => {
              if (result) {
                if (result.street === null || result.street.length === 0) {
                  this.toastr.show(
                    this.translate.TOASTR.CEP_NOT_ADDRESS.DESCRIPTION,
                    this.translate.TOASTR.CEP_NOT_ADDRESS.TITLE,
                    null,
                    'error',
                  );
                }
                this.registrationForm.get('address').setValue(result.street);
                this.registrationForm.get('neighborhood').setValue(result.neighborhood);
                this.registrationForm.get('city').setValue(result.city);
                this.registrationForm.get('state').setValue(result.state);
                this.editAddressNumber();
              }
            },
            err => {

              this.registrationForm.get('address').setValue('');
              this.registrationForm.get('addressNumber').setValue('');
              this.registrationForm.get('neighborhood').setValue('');
              this.registrationForm.get('city').setValue('');
              this.registrationForm.get('state').setValue('');

              this.toastr.show(
                this.translate.TOASTR.CEP_NOT_FOUND.DESCRIPTION,
                this.translate.TOASTR.CEP_NOT_FOUND.TITLE,
                null,
                'error',
              );
            }
          );
        this.subscriptions.add(subscription);
      } else {
        this.registrationForm.get('address').setValue('');
        this.registrationForm.get('addressNumber').setValue('');
        this.registrationForm.get('neighborhood').setValue('');
        this.registrationForm.get('city').setValue('');
        this.registrationForm.get('state').setValue('');
      }
    });

    this.subscriptions.add(subscriptionCep);
  }

  private getEvent() {
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);

    // Payments
    for (let index = 1; index <= this.event.installments; index++) {
      this.installments.push(`${index}X`);
    }
  }

  private cancelSubscriptionAPI(subscription: Subscription, dialogRef: MatDialogRef<CrossXDialogComponent>): void {
    this.subscriptionService.cancelSubscription(subscription.id)
      .subscribe(
        result => {
          this.isCancelSubscription = true;
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }, err => {

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.CANCEL_SUBSCRIPTION.ERROR,
            null,
            'error',
          );

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }
      );
  }
}
